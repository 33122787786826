































































































































import LoginPageTs from './LoginPageTs';
export default class LoginPage extends LoginPageTs {}
